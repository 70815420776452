import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { selectRelatedPagosRecebidos } from '../../features/faturamento/faturamentoSelectors';
import QCXDataGrid from '../../shared-components/data-grid/QCXDataGrid';
import { unnormalizeNumeral } from '../../utils/general/general-utils';
import NumerarioImportacaoUtils from '../../utils/general/numerario-importacao/NumerarioImportacaoUtils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import { removeDespesaAsync } from '../../features/faturamento/faturamentoThunks';


const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '16px 0',
  },
}));

export default function QCXNumerario() {
  const classes = useStyles();
  const dispatch = useDispatch();
  

  const { t } = useTranslation();

  const pagosRecebidos = useSelector(selectRelatedPagosRecebidos);

  const pagosRecebidosRows = useMemo(
    () => pagosRecebidos.filter((pagoRecebido) => pagoRecebido.origem === 'NUMERARIO'),
    [pagosRecebidos]
  );

  const removeNumerario = (id) => {
    dispatch(removeDespesaAsync(id));
  };

  const columns = useMemo(
    () => [
      {
        field: 'numerario',
        headerName: t('com.muralis.qcx.numerario.label'),
        flex: 90,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row }) => NumerarioImportacaoUtils.formatIdentificacao(row?.numerario?.identificacao) || '-',
      },
      {
        field: 'valor',
        headerName: t('com.muralis.qcx.valor'),
        flex: 80,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row: { totalMoeda } }) =>
          unnormalizeNumeral(totalMoeda, formatBrazilianNumericDecimal(2)) || '-',
      },
      {
        field: 'valorReais',
        headerName: t('com.muralis.qcx.moeda.valorReais'),
        flex: 90,
        type: 'string',
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ row: { totalReal } }) => unnormalizeNumeral(totalReal, formatBrazilianNumericDecimal(2)) || '-',
      },
      {
        field: 'acoes',
        headerName: t('com.muralis.qcx.acoes.label'),
        flex: 50,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => (
          <IconButton onClick={() => removeNumerario(row.id)}>
            <DeleteIcon color="error" />
          </IconButton>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle className={classes.subtitle} title={t('com.muralis.qcx.numerario.labelPlural')} />
      </Grid>
      <Grid item xs={12}>
        <QCXDataGrid columns={columns} rows={pagosRecebidosRows} />
      </Grid>
    </>
  );
}
