import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons';
import { isEmpty, isFunction } from 'lodash';
import { bindTrigger } from 'material-ui-popup-state';
import { PopupState, bindMenu } from 'material-ui-popup-state/core';
import { Dispatch, MouseEvent, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCalculator as FaCalculatorIcon } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import QCXMoveFocusInside from '../../../../../components/focus-lock/QCXMoveFocusInside';
import { userHasUpdateRole } from '../../../../../components/perfil/perfil-utils';
import QCXPopupState from '../../../../../components/popup-state/QCXPopupState';
import { selectRelatedCalculoModel } from '../../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import {
  changeLastHistoryModel,
  declaracaoImportacaoActions,
  failure,
  loading,
  setError,
  setRelatedCalculoModel,
  setResponse,
  success,
  updateOnList,
} from '../../../../../features/declaracao-importacao/declaracaoImportacaoSlice';
import { faturaAPI } from '../../../../../features/fatura/faturaAPI';
import QCXFinalFormDialog from '../../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXFinalDatePickerField from '../../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXListItemIcon from '../../../../../shared-components/list-item-icon/QCXListItemIcon';
import { DECLARACAO_IMPORTACAO_ENDPOINT } from '../../../../../utils/api/domain/domain-endpoint-utils';
import { getCurrentDate, normalizeData } from '../../../../../utils/general/general-utils';
import { isNaoCalculadaStatus } from '../../../../../utils/general/operations/operationUtils';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  SUBMITTING_STATUS,
  isOpenStatus,
} from '../../../../../utils/hooks/form/dialog/formDialogUtils';
import useFormDialogSync from '../../../../../utils/hooks/form/dialog/useFormDialogSync';
import { required } from '../../../../../utils/validators/field/validator';

export interface DeclaracaoImportacaoActionsMenuProps {
  id: number;
  calculada: boolean;
  atributosAdicionais: any;
  metaFields: any;
  active: boolean;
  authInfo: any;
}

const DeclaracaoImportacaoActionsMenu = ({
  id,
  calculada,
  atributosAdicionais,
  metaFields,
  active,
  authInfo,
}: DeclaracaoImportacaoActionsMenuProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const calculoRelatedModel = useSelector(selectRelatedCalculoModel);
  const history = useHistory();

  const [handleCalcularClick, formDialogCalculoStatus, handleFormDialogCalculoStatus] = useFormDialogSync(
    async (
      event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>,
      data: {
        id: number;
        calculada: boolean;
        atributosAdicionais: any;
        active: boolean;
      }
    ) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedCalculoModel({
            id: data?.id,
            calculada: data?.calculada,
            atributosAdicionais: {
              tipo: data?.atributosAdicionais?.tipo,
              processo: data?.atributosAdicionais?.processo,
            },
            active: data?.active,
          })
        );
      }
    },
    []
  ) as [(...args: any[]) => void, string, Dispatch<SetStateAction<string>>];

  const formDialogCalculoContent = useMemo(
    () =>
      t('com.muralis.qcx.mensagem.inserirDataCalcularRecalcularDIDUIMP', {
        acao: isNaoCalculadaStatus(calculoRelatedModel?.calculada)
          ? t('com.muralis.qcx.acoes.calcular').toLowerCase()
          : t('com.muralis.qcx.acoes.recalcular').toLowerCase(),
        processo: calculoRelatedModel?.atributosAdicionais?.processo,
      }),
    [calculoRelatedModel]
  );

  const isFormDialogCalculoOpen = useMemo(() => isOpenStatus(formDialogCalculoStatus), [formDialogCalculoStatus]);

  const handleCloseFormDialogCalculo = useCallback(
    () => handleFormDialogCalculoStatus(CLOSED_STATUS),
    [handleFormDialogCalculoStatus]
  );

  const handleCalcularByDataSubmit = useCallback(
    async (values: any) => {
      try {
        dispatch(loading());

        handleFormDialogCalculoStatus(SUBMITTING_STATUS);

        const payload = {
          atributosAdicionais: {
            tipo: values?.atributosAdicionais?.tipo,
            dataCalculo: normalizeData(values?.atributosAdicionais?.dataCalculo),
          },
        };

        const response = await faturaAPI.calculateById(
          id,
          [DECLARACAO_IMPORTACAO_ENDPOINT],
          //@ts-ignore
          payload
        );

        if (response.status === 200) {
          const isNaoCalculada = isNaoCalculadaStatus(values?.calculada);
          const processo = values?.atributosAdicionais?.processo;
          const responseMessage = t('com.muralis.qcx.mensagem.DIEstado', {
            processo,
            estado: isNaoCalculada ? t('com.muralis.qcx.estado.calculada') : t('com.muralis.qcx.estado.recalculada'),
          });

          const simplifiedResponse = {
            status: response?.status,
            message: responseMessage,
            data: response?.data,
          };

          dispatch(success());
          dispatch(
            updateOnList({
              data: response?.data,
            })
          );
          dispatch(setResponse(simplifiedResponse));

          if (isNaoCalculada) {
            dispatch(
              changeLastHistoryModel({
                processo: values?.atributosAdicionais?.processo,
              })
            );
          }

          dispatch(declaracaoImportacaoActions.refresh());

          handleFormDialogCalculoStatus(DONE_STATUS);
          history.push(t('com.muralis.qcx.url.importacaoDeclaracoesImportacaoDetalhes', { id: response?.data?.id }));
        }
      } catch (error: any) {
        handleFormDialogCalculoStatus(FAIL_STATUS);

        const isUnknownError =
          isEmpty(error?.response) || isEmpty(error?.response?.data) || isEmpty(error?.response?.data?.message);

        const isNaoCalculada = isNaoCalculadaStatus(values?.calculada);
        const errorMessage = isUnknownError
          ? t('com.muralis.qcx.erro.erroCalcularRecalcular', {
              estado: isNaoCalculada ? t('com.muralis.qcx.acoes.calcular') : t('com.muralis.qcx.acoes.recalcular'),
            })
          : t('com.muralis.qcx.erro.erroCalcularRecalcularEspecifico', {
              estado: isNaoCalculada ? t('com.muralis.qcx.acoes.calcular') : t('com.muralis.qcx.acoes.recalcular'),
              mensagem: error?.response?.data?.message,
            });

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
        dispatch(setResponse(error.response));
      }
    },
    [handleFormDialogCalculoStatus, id]
  );

  return (
    <>
      <QCXPopupState popupId="popup-menu-li">
        {(popupState: PopupState) => (
          <>
            <IconButton
              key={`btn-more-options-${id}`}
              name={`btn-more-options-${id}`}
              style={{ padding: 0, margin: 0 }}
              {...bindTrigger(popupState)}
            >
              <MoreHorizIcon color="secondary" />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              {userHasUpdateRole(authInfo, 'importacao-di-duimp') && (
                <>
                  <MenuItem
                    onClick={(event) => {
                      popupState.close();
                      handleCalcularClick({
                        id,
                        calculada,
                        atributosAdicionais,
                        active,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <FaCalculatorIcon color="green" size={20} />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {isNaoCalculadaStatus(calculada)
                        ? t('com.muralis.qcx.acoes.calcular').toUpperCase()
                        : t('com.muralis.qcx.acoes.recalcular').toUpperCase()}
                    </Typography>
                  </MenuItem>
                </>
              )}
            </Menu>
          </>
        )}
      </QCXPopupState>
      <QCXFinalFormDialog
        key="form-dialog-calculo-di-duimp"
        id="form-dialog-calculo-di-duimp"
        title={t('com.muralis.qcx.DIDUIMP.calculoDeclaracaoDIDUIMP')}
        content={formDialogCalculoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: isNaoCalculadaStatus(calculoRelatedModel?.calculada)
                ? t('com.muralis.qcx.acoes.calcular')
                : t('com.muralis.qcx.acoes.recalcular'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogCalculoOpen}
        initialValues={calculoRelatedModel}
        onSubmit={handleCalcularByDataSubmit}
        onClose={handleCloseFormDialogCalculo}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXFinalDatePickerField
              id="date-picker-data-calculo-field"
              key="date-picker-data-calculo-field"
              name="atributosAdicionais.dataCalculo"
              label={t('com.muralis.qcx.dataCalculo')}
              defaultValue={getCurrentDate()}
              validate={required}
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
    </>
  );
};

export default DeclaracaoImportacaoActionsMenu;
